import React from "react";
import AuthContext from '../context/AuthContext';
import UserLogin from "../components/user/UserLogin";
import { makeStyles } from '@mui/styles';
import { NewEventProcess } from "../components/eventcreate";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles({
        root: {
            flexGrow: 1,
        }
    });

    export default function NewEvent(props) {
        const classes = useStyles();

        const authContext = React.useContext(AuthContext);
        const [aState, setAState] = React.useState({loggedIn:false, adID:null, level:0, token:null, expiry:null});
        
        React.useEffect(() => {
          setAState(prev => {
              return({...authContext.accountState});
          });
        }, [authContext]);

        //check for token, block if not logged in
        if(aState.loggedIn === false) {
            return <UserLogin />
        }

        return (
          <div className={classes.root}>
            <Box m={1.5} bgcolor="background.paper" >
                <Grid container >
                    <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <NewEventProcess />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                    </Grid>
                </Grid>
            </Box>
        </div>
      )
  }