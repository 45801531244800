import React, {useEffect, useState, useContext } from "react";
import { makeStyles } from '@mui/styles';
import ResourcesFilter from "./ResourcesFilter";
import AContentList from "./AContentList/AContentList";
import { AldersgateContext } from "../../context/aldersgate";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';



const useStyles = makeStyles({
        root: {
            flexGrow: 1,
        }
    });


    export default function ResourcesView(props) {
        const { category, showHeader } = props;

        const [contentList, setContentList] = useState([]);
        const aldersgateContext = useContext(AldersgateContext);

        useEffect(() => {
            //SearchForContent({category: category, type: 'all', language: 'en', title: ''});
        }, [category]);

        const SearchForContent = (searchFilterData) => {

            let queryString = 'category=' + searchFilterData.category + '&type=' + searchFilterData.type + '&language=' + searchFilterData.language + '&title=' + searchFilterData.title;

            aldersgateContext.PostToAPI('content/cqry', searchFilterData, queryString, 1).then((success, err) => {
                if(success) {
                    setContentList(ProcessContent(success));
                }
        
                if(err) {
                    console.log("Error: " + err);
                }
        
            }).catch((error) => {
                console.log("Error: " + error);
            });
        }

        const ProcessContent = (content) => {
            let contentList = [];
    
            let index = 0;
            content.forEach(item => {
                contentList.push({
                    ...item, id: index++
                });
            });
    
            return contentList;
        }

        return (
            <Box>
                {showHeader ? (
                    <Box>
                        <Typography variant="h2">Resources</Typography>
                    </Box>
                ) : null}
                <Box mt={2}><ResourcesFilter category={category} onSearch={SearchForContent} disableCategory={!showHeader} /></Box>
                <AContentList contentList={contentList} />
            </Box>
      )
  }