import React, {useEffect, useState, useContext} from  'react';
import { AldersgateContext } from '../../../../context/aldersgate';
import ADEventList from './ADEventList/ADEventList';
import EventsFilter from './EventsFilter';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import EventViewModal from './EventViewModal';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ImportFromSF from './ImportFromSF';

export default function AEventsView(props) {

    const { searchFilter, searchQuery } = props;
    
    const currentYear = new Date().getFullYear();
    const firstDay = new Date(currentYear, 0, 1);
    const lastDay = new Date(currentYear, 11, 31);

    const [filterData, setFilterData] = useState({eventType: 'All', eventStatus: 'All', startDate: firstDay, endDate: lastDay, name: ''});
    const [eventData, setEventData] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [actionModalOpen, setActionModalOpen] = useState(false);
    const [displayFilter, setDisplayFilter] = useState(true);
    const [sfImportModalOpen, setSFImportModalOpen] = useState(false);

    const aldersgateContext = useContext(AldersgateContext);

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4
      };

      useEffect(() => {
        if(searchFilter) {
            setFilterData(searchFilter);
            setDisplayFilter(false);
        }
    }, [searchFilter]);

    useEffect(() => {
        if(searchQuery) {
            QueryForEvents(searchQuery);
            setDisplayFilter(false);
        }
    }, [searchQuery]);

    function ProcessEvents(events) {
        let eventList = [];

        let index = 0;
        events.forEach(event => {
            let location = 'Unknown';
            if(event !== undefined && event !== null) {
                let loc = [];

                if(event.city !== undefined && event.city !== null && event.city !== '')   
                    loc.push(event.city);
                if(event.state !== undefined && event.state !== null && event.state !== '')
                    loc.push(event.state);
                if(event.countryID !== undefined && event.countryID !== null && event.countryID !== '')
                    loc.push(event.countryID);

                if(loc.length > 0) {
                    location = loc.join(', ');
                } else {
                    location = 'Unknown';
                }
            }

            if(event.user) {
                event.ownerName = event?.user?.fName + ' ' + event?.user?.lName;
            }

            let participantCount = event.participants ? event.participants.length : 0;

            eventList.push({
                ...event, id: index++, location: location, participantCount: participantCount
            });
        });

        return eventList;
    }

    function HandleFilterOnChange(parameter, filterData) {
        setFilterData(prevState => {
            return {...prevState, [parameter]: filterData};
        });
    }

    function SearchWithFilter() {
        let query = '';
        if(filterData.eventType !== 'All')
            query += '&eventType=' + filterData.eventType;
        if(filterData.eventStatus !== 'All')
            query += '&eventStatus=' + filterData.eventStatus;
        if(filterData.startDate !== '')
            query += '&startDateRange=' + filterData.startDate;
        if(filterData.endDate !== '') {
            let endDate = new Date(filterData.endDate);
            endDate.setDate(endDate.getDate() + 1); // Add 1 day to include the last day
            query += '&endDateRange=' + endDate;
        }

        if(filterData.reportStatus !== undefined && filterData.reportStatus !== null && filterData.reportStatus !== 'All' && filterData.reportStatus !== '') {
            query += '&reportStatus=' + filterData.reportStatus;
        }

        if(filterData.reportAppStatus !== undefined && filterData.reportAppStatus !== null && filterData.reportAppStatus !== 'All' && filterData.reportAppStatus !== '') {
            query += '&reportAppStatus=' + filterData.reportAppStatus;
        }

        if(filterData.name !== '')
            query += '&name=' + filterData.name;

        query += '&queryLimit=10';

        query += '&includeOwner=true';

        QueryForEvents(query);
    }

    function QueryForEvents(query) {
        console.log(query);

        aldersgateContext.GetFromAPI('admin/getlist/eventquery/s', query, 1).then((success, err) => {
            if(success) {
                console.log("Success: " + JSON.stringify(success, null, 2));
                setEventData(ProcessEvents(success));
            }
    
            if(err) {
                console.log("Error: " + err);
                setEventData([]);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
            setEventData([]);
        });
    }

    function viewEventDetails(event){
        let eventID = event;
        console.log('viewEventDetails: ' + eventID);

        //find the event in the reportData
        let eventItem = eventData.find((item) => item.id === eventID);

        console.log('eventItem: ' + JSON.stringify(eventItem, null, 4));

        setSelectedEvent(eventItem.eventID);

        setActionModalOpen(true);

    }

    return (
        <Grid container spacing={3}>
            {displayFilter ? (
            <Grid item xs={12}>
                <EventsFilter search={SearchWithFilter} setFilterData={setFilterData}/>
            </Grid>
            ) : null}
            <Grid item xs={12}>
                <ADEventList eventListData={eventData} onRowClick={viewEventDetails}/>
                <Modal
                open={actionModalOpen}
                onClose={()=>{setActionModalOpen(false)}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <EventViewModal eventID={selectedEvent} closeModal={()=>{setActionModalOpen(false)}}/>
                    </Box>
                </Modal>

            </Grid>
            {displayFilter ? (
                <Grid item xs={12}>
                    <ImportFromSF/>
                </Grid>) : null
            }
            <Grid item xs={12} mb={5}>
            </Grid>
        </Grid>
    );
}