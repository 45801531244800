import React, { useState, useEffect, useContext } from 'react';
import SFImportModal from './SFImportModal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import BackupIcon from '@mui/icons-material/Backup';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';


export default function ImportFromSF(props) {
    
    const [sfImportModalOpen, setSFImportModalOpen] = useState(false);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4
      };

      return (
        <Box sx={{display:'flex', justifyContent:'center'}}>
            <Button variant="contained" color="primary" onClick={()=>{setSFImportModalOpen(true)}}>Import from SF</Button>
            <Modal
            open={sfImportModalOpen}
            onClose={()=>{setSFImportModalOpen(false)}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <SFImportModal closeModal={()=>{setSFImportModalOpen(false)}}/>
                </Box>
            </Modal>
        </Box>
    );
}