import React, { useState, useEffect } from 'react';
import EventSFIngest from './EventSFIngest';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';

export default function EventSFIDField(props) {

    const { eventData, elementData, OnElementChange, onCreateInSalesforce } = props;

    const [fieldData, setFieldData] = useState(null);
    const [lastValue, setLastValue] = useState(null)
    const [editField, setEditField] = useState(false);
    const [showCloud, setShowCloud] = useState(true);
    const [showSFIngest, setShowSFIngest] = useState(false);

    useEffect(() => {
        setFieldData(eventData.salesforceID);
    }, [eventData]);

    const handelStartEdit = () => {
        setLastValue(fieldData);
        setEditField(true);
    }

    const handleCancelEdit = () => {
        setFieldData(lastValue);
        setEditField(false);
    }

    const handleSaveEdit = () => {
        if(OnElementChange)
            OnElementChange(fieldData);

        setEditField(false);
    }

    const handleCreateInSalesforce = () => {
        setShowCloud(false);
        setShowSFIngest(true);

        // if(onCreateInSalesforce)
        //     onCreateInSalesforce();
    }

    const DisplaySFID = () => {
        return (
            <Stack direction="row" justifyContent="flex-start" alignItems="center">
                <Box>
                    <Link href={"https://iliteam.lightning.force.com/lightning/r/Conference__c/" + fieldData + "/view"} target="_blank" rel="noopener noreferrer">
                        {fieldData}
                    </Link>
                </Box>
                <Box>
                    <IconButton aria-label="Edit" onClick={() => {handelStartEdit()}}><EditIcon /></IconButton>
                </Box>
            </Stack>
        );
    }

    const OnCompleteMatchInSalesforce = (sfID) => {
        console.log('OnCompleteMatchInSalesforce: ' + sfID);

        OnElementChange(sfID);
        setShowSFIngest(false);
    }

    const DisplayNotIngested = () => {
        return (
            <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
                <Stack direction="row" justifyContent="flex-start" alignItems="center">
                    <Box>
                        <Typography variant="body1" component="p" onClick={() => {handelStartEdit()}}>Not Ingested</Typography>
                    </Box>
                    {showCloud ? (
                        <Box>
                            <IconButton aria-label="Edit" onClick={() => {handleCreateInSalesforce()}}><CloudUploadIcon /></IconButton>
                        </Box>
                    ) : null}
                </Stack>
                {showSFIngest ? (<EventSFIngest event={eventData} onCompleteMatchInSalesforce={OnCompleteMatchInSalesforce} onCreateInSalesforce={onCreateInSalesforce}/>) : null}
            </Stack>
        );
    }

    if(editField) {
        return (
            <Stack direction="row" justifyContent="flex-start">
                <TextField
                    id="email"
                    label="Conference ID"
                    variant="standard"
                    size='small'
                    value={fieldData}
                    onChange={(e) => setFieldData(e.target.value)}
                    sx={{width: '275px'}}
                    
                />
                <IconButton aria-label="Cancel" onClick={() => {handleCancelEdit()}}><CancelIcon color='error' /></IconButton>
                <IconButton aria-label="Save" onClick={() => {handleSaveEdit()}}><CheckCircleIcon color='success' /></IconButton>
            </Stack>
        );
    } else {
        return (
            <Box>
                {fieldData ? <DisplaySFID /> : <DisplayNotIngested />}
            </Box>
        );
    }
}