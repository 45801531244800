import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function GroupDisplay(props) {
    const {groupData, setGroupData, deleteGroupMember} = props;

    const [group, setGroup] = useState({});
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    useEffect(() => {
        setGroup(groupData);
    }, [groupData]);

    const handleDeleteIntent = () => {
        console.log('Delete Group: ', group);
        setShowConfirmDelete(true);
    }

    const handleCancelDelete = () => {
        console.log('Cancel Delete Group: ', group);
        setShowConfirmDelete(false);
    }

    const handelConfirmDelete = () => {
        console.log('Confirm Delete from Group: ', group);
        setShowConfirmDelete(false);

        deleteGroupMember(group.eventgroupmember.adID);
    }

    const handlePrimary = () => {
        console.log('Primary Group: ', group);


        let changedData = {...group };

        changedData.eventgroupmember.mainGroup = !group.eventgroupmember.mainGroup;

        setGroupData({changedGroup: changedData});

    }
    
    return (
        <Paper elevation={2}>
            <Box sx={{position:'relative', display:'flex', flexDirection:'row'}} m={2} p={1}>
                <Box>
                    <Typography variant="body1">
                        {group?.name}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="body1">
                        
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="body1">
                        {group?.eventgroupmember?.groupStatus}
                    </Typography>
                </Box>
                <Box sx={{position: 'absolute', top: 0, right: 0}}>
                    <Box sx={{display:'flex', flexDirection:'row', alignItems:'flex-start'}}>
                        {showConfirmDelete ? 
                            <Box>
                                <IconButton aria-label="confirm" color="success" onClick={handelConfirmDelete}>
                                    <CheckCircleIcon />
                                </IconButton>
                                <IconButton aria-label="cancel" color="error" onClick={handleCancelDelete}>
                                    <CancelIcon />
                                </IconButton>
                                <IconButton aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        : 
                        <IconButton aria-label="delete" color="error" onClick={handleDeleteIntent}>
                            <DeleteIcon />
                        </IconButton>
                        }
                        <IconButton aria-label="primary" color="disabled" onClick={handlePrimary}>
                            {group?.eventgroupmember?.mainGroup ? <StarIcon /> : <StarBorderIcon />}
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Paper>
    );
}