import React, { useEffect, useState, useContext } from 'react';
import { AldersgateContext } from '../../../../context/aldersgate';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function EventGroupsAutocomplete(props) {
    const { groupSelected } = props;

    const [groupSelectData, setGroupSelectData] = useState([]);
    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {
        aldersgateContext.GetFromAPI('group/getlist/allgroups', '', 1).then((success, err) => {
            if(success) {
                console.log("Success: " + JSON.stringify(success, null, 2));
                setGroupSelectData(ProcessGroups(success));
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });
    }, []);

    function ProcessGroups(groups) {
        let groupList = [];
        let index = 0;

        groups.forEach(group => {
            groupList.push({
                label: group.name, id: index++, value: group.adID
            });
        });

        //sort by name
        groupList.sort((a, b) => {
            if(a.label < b.label) { return -1; }
            if(a.label > b.label) { return 1; }
            return 0;
        });

        return groupList;
    }

    const OnHandleChange = (event, value) => {
        console.log('OnHandleChange: ', value);

        if(groupSelected)
            groupSelected(value.value);
    }

    return (
        <Autocomplete
          disablePortal
          id="group-autocomplete"
          options={groupSelectData}
          sx={{ width: 300 }}
          onChange={OnHandleChange}
          renderInput={(params) => <TextField {...params} label="Add Group" size='small' />}
        />
      );

}