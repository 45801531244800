import React, {useEffect, useState, useContext} from  'react';
import { AldersgateContext } from '../../../../context/aldersgate';
import AddContentPost from './AddContentPost';
import Editor from 'react-simple-wysiwyg';
import sanitizeHtml from 'sanitize-html';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';


export default function ViewContentPost(props) {
    const { content } = props;

    const [contentData, setContentData] = useState({});

    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {
        setContentData(content);
    }, [content]);

    return (<Box><AddContentPost contentData={content} /></Box>);
        

}
