import React, {useState, useEffect, useRef, ReactNode, useContext } from 'react';
import jsQR from "jsqr";
import Webcam from "react-webcam";
import SophConstants from '../../../constants/SophConstants';
import { AldersgateContext } from '../../../context/aldersgate';
import QRResultsDisplay from './QRResultsDisplay';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const videoConstraints = {
    width: 512,
    height: 512,
    facingMode: "environment", //user
    rameRate: { ideal: 30, max: 30 }
  };

export default function QRCheckIn(props) {
    const { eventSignupID, UpdateParticipant } = props;

    const [checkInState, setCheckInState] = useState(0); //0 inactive, 1 scanning, 2 found qr, 3 checking qr, 4 received response, 5 submitting check in, 6 check in complete
    const [qrCode, setQrCode] = useState({data: null, valid: false});
    const [participantData, setParticipantData] = useState({valid:false, checkedIn:false});
    const [webcamReady, setWebcamReady] = useState(false);
    const aldersgateContext = useContext(AldersgateContext);

    const timer = React.useRef();
    const imageGrabberInterval = React.useRef();
    const webcamRef = useRef(null);

    // let canScanButton = false;

    // useEffect(() => {
    //     console.log("Webcam ready: " + webcamReady);
    //     canScanButton = webcamReady;
    // }, [webcamReady]);


    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
            clearInterval(imageGrabberInterval.current);
        };
    }, []);

    const ScanForQRCode = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();

            //https://stackoverflow.com/questions/57271776/how-to-rotate-the-image-captured-using-the-getscreenshot-method-in-react-webcam
            //convert image to Uint8ClampedArray
            let cImage = document.createElement("img");
            cImage.src = imageSrc;
            cImage.onload = () => {
                var canvas = document.createElement("canvas");
                canvas.width = cImage.width;
                canvas.height = cImage.height;
                let ctx = canvas.getContext("2d");
                ctx.drawImage(cImage, 0, 0, cImage.width, cImage.height);
                let imageData = ctx.getImageData(0, 0, cImage.width, cImage.height);

                const code = jsQR(imageData.data, cImage.width, cImage.height);

                //if a QR code was found
                if (code) {

                    clearTimeout(timer.current);
                    clearInterval(imageGrabberInterval.current);

                    setCheckInState(2);

                    CheckQRData(code.data);
                } else {
                    console.log("No QR code found");
                }
            };
            
        },
        [webcamRef]
    );

    const CheckQRData = (data) => {
        let qrData = data.trim();

        try {
            let jsonData = JSON.parse(qrData);
            if(jsonData && jsonData.eID && jsonData.eID.length > 0 && jsonData.pID && jsonData.pID.length > 0) {

                    //check if the qr event id matches the event we are checking in for
                    if(jsonData.eID !== eventSignupID) {
                        setParticipantData({...participantData, checkedIn:false, valid:false});
                        setCheckInState(6);
                        return;
                    }

                setQrCode({data: qrData, valid:true});

                setCheckInState(3);

                aldersgateContext.GetFromAPI('event/' + eventSignupID + '/validateparticipant/' + jsonData.pID, null, 1).then((success, err) => {
                    if(success) {
                        if(success && success.valid) {
                            setParticipantData({...success, checkInConfirmed:false});
                        } else {
                            setParticipantData({valid:false, checkedIn:false});
                        }
    
                        setCheckInState(4);
                    }
            
                    if(err) {
                        console.log("Error: " + err);
                        setCheckInState(4);
                        setQrCode({data:null, valid:false});
                    }
            
                }).catch((error) => {
                    console.log("Error: " + error);
                    setCheckInState(4);
                    setQrCode({data:null, valid:false});
                });

                //fetch(SophConstants.API_ADDR + '/api/event/' + jsonData.eID + '/validateparticipant/' + jsonData.pID + '?api-key=foo', {
                
            } else {
                console.log("Invalid QR data");
                setCheckInState(4);
                setQrCode({data:null, valid:false});
            }
        } catch (e) {
            console.log("Error parsing QR data");
            setCheckInState(4);
            setQrCode({data:null, valid:false});
        }

    }

    const HandleCheckInBtnAction = () => {
        setCheckInState(5);

        //check if the qr event id matches the event we are checking in for
        if(participantData.evntSignupID !== eventSignupID) {
            setParticipantData({...participantData, checkedIn:false});
            setCheckInState(6);
            return;
        }

        fetch(SophConstants.API_ADDR + '/api/event/' + participantData.evntSignupID + '/checkin/' + participantData.adID + '?api-key=foo', {
            method: 'post',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            body: null
        }).then((res) => {
            return res.json();
        }).then((jsonData, status) => {

            if(jsonData && jsonData.checkedIn) {
                console.log("Checked in");
                setParticipantData({...participantData, checkedIn:true});
                UpdateParticipant({ participantADID:participantData.adID, checkedIn:true });
            }

            setCheckInState(6);

        }).catch((err) => {
            console.log(err);
            setParticipantData({...participantData, checkedIn:false});
            setCheckInState(6);
        });
    }

    const HandleScanForQRBtnAction = () => {
        setCheckInState(1);
        
        timer.current = setTimeout(() => {
            clearInterval(imageGrabberInterval.current);

            if(checkInState < 2) {
                setCheckInState(4);
                setParticipantData({valid:false, checkedIn:false});
                setQrCode({data: null, valid: false});
            }

        }, 6000);

        imageGrabberInterval.current = setInterval(() => {
                ScanForQRCode();
        }, 250);
    }

    const HandleResetCheckIn = () => {
        setCheckInState(0);
        setQrCode({data: null, valid: false});
        setParticipantData({valid:false, checkedIn:false});
    }

    const DisplayResult = () => {

        switch (checkInState) {
            case 0:
                return null;
            case 1:
                return null;
            case 2:
                return <LargeCircularProgress />;
            case 3:
                return <LargeCircularProgress />;
            case 4:
                return <RenderQRParticipantResponse />;
            case 5:
                return <RenderQRParticipantResponse />;
            case 6:
                return <RenderCheckedInResponse />;
            default:
                return null;
        }
    }

    const RenderQRParticipantResponse = () => {
        if(!qrCode.valid) {
            return (
                <QRResultsDisplay icon={<QrCode2Icon sx={{fontSize:100, color:'textSecondary'}} />} isLoading={false}>
                    <Typography variant='h6'>No Valid QR Code Found</Typography>
                </QRResultsDisplay>
            );
        } else {
            if(participantData.valid) {
                return (
                    <QRResultsDisplay icon={participantData.checkedIn ? <PrivacyTipIcon sx={{fontSize:100, color:'textSecondary'}} /> : <AccountBoxIcon sx={{fontSize:100, color:'textSecondary'}} />} isLoading={false}>
                        <Box sx={{fontSize: {xs:20, sm:30}, fontWeight:'bold'}}>
                            <Typography variant="p" component="p" align='center' >
                                {participantData.fName} {participantData.lName}
                            </Typography>
                            <Typography variant="p" component="p" align='center'>
                                {participantData.city}, {participantData.state}, {participantData.countryID}
                            </Typography>
                            <Typography variant="p" component="p" align='center'>
                                {participantData.phoneNumber}
                            </Typography>
                            <Typography variant="p" component="p" align='center'>
                                {participantData.email}
                            </Typography>

                            {participantData.checkedIn ? <Typography variant="p" component="p" align='center' color='red' mt={2}>Invalid: Ticket Already Used</Typography> : null}

                        </Box>
                    </QRResultsDisplay>
                );
                
            } else {
                return (
                    <QRResultsDisplay icon={<DisabledByDefaultIcon sx={{fontSize:100, color:'red'}} />} isLoading={false}>
                        <Typography variant='h6'>Invalid Ticket</Typography>
                    </QRResultsDisplay>
                )
            }
        }
    }

    const RenderCheckedInResponse = () => {
        if(participantData.valid) {
                return (
                    <QRResultsDisplay icon={participantData.checkedIn ? <CheckIcon sx={{fontSize:100, color:'green'}} /> : <PrivacyTipIcon sx={{fontSize:100, color:'textSecondary'}} />} isLoading={false}>
                    <Box sx={{fontSize: {xs:20, sm:30}, fontWeight:'bold'}}>
                        <Typography variant="p" component="p" align='center' >
                            {participantData.fName} {participantData.lName}
                        </Typography>
                        <Typography variant="p" component="p" align='center'>
                            {participantData.city}, {participantData.state}, {participantData.countryID}
                        </Typography>
                        <Typography variant="p" component="p" align='center'>
                            {participantData.phoneNumber}
                        </Typography>
                        <Typography variant="p" component="p" align='center'>
                            {participantData.email}
                        </Typography>

                        {participantData.checkedIn ? <Typography variant="p" component="p" align='center' color='green' mt={2}>Checked In!</Typography> : <Typography variant="p" component="p" align='center' color='red' mt={2}>Unable to Check In</Typography>}

                    </Box>
                </QRResultsDisplay>
                )
        } else {
            return (
                <QRResultsDisplay icon={<DisabledByDefaultIcon sx={{fontSize:100, color:'red'}} />} isLoading={false}>
                    <Typography variant='h6'>Invalid Ticket</Typography>
                </QRResultsDisplay>
            )
        }
    }


    const LargeCircularProgress = () => {
        return (
            <Box sx={{position:'absolute', top:'50%', left:'50%', marginTop:'-50px', marginLeft:'-50px'}}>
                <CircularProgress size={100} />
            </Box>
        )
    }

    const DisplayParticipantcheckResponse = () => {
        if(participantData.valid) {
            return (
                null
            )
        } else {
            return (
                null
            )
        }
    }

    const RenderActionButtons = (renderActionProps) => {
        const { canScan } = renderActionProps;

        switch(checkInState) {
            case 0:
                return <RenderScanButton canScan={canScan} />;
            case 1:
                return <RenderScanButton canScan={canScan} />;
            case 2:
                return <RenderScanButton canScan={canScan} />;
            case 3:
                return <RenderScanButton canScan={canScan} />;
            case 4:
                return <RenderCheckInButtons />;
            case 5:
                return <RenderCheckInButtons />;
            case 6:
                return <RenderCheckInButtons />;
            default:
                return null;
        }
    }

    const RenderScanButton = (renderProps) => {
        const { canScan } = renderProps;
        let isDisabled = false;

        if(!canScan) {
            isDisabled = true;
        } else {
            if(checkInState == 1) {
                isDisabled = true;
            }
        }

        return (
            <Box>
                <Button variant='contained' size="large" disabled={isDisabled} startIcon={<QrCodeScannerIcon />} onClick={HandleScanForQRBtnAction} >Scan QR</Button>
                {checkInState == 1 && (
                <CircularProgress
                    size={24}
                    sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                    }}
                />
                )}
            </Box>
        )
    }

    const RenderCheckInButtons = () => {
        if(participantData.valid) {
            let btnDisabled = false;

            if(checkInState > 4) {
                btnDisabled = true;
            }

            if(!btnDisabled) {
                if(participantData.checkedIn) {
                    btnDisabled = true;
                }
            }

            return (
                <Box sx={{width:'100%'}}>
                    <Stack direction="row" spacing={2}>
                        <Button variant='contained' size="large" onClick={HandleResetCheckIn} color='error'>Close</Button>
                        <Box sx={{width:'40px'}}></Box>
                        <Box sx={{position:'relative'}}>
                            <Button variant='contained' size="large"  disabled={btnDisabled} onClick={HandleCheckInBtnAction} >Complete Check In</Button>
                            {checkInState == 5 && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Box>
                    </Stack>
                </Box>
            )
        } else {
            return (
                <Box sx={{width:'100%'}}>
                    <Box  sx={{display:'flex', flexDirection:'row', justifyContent:'center', width:'100%'}}>
                        <Button variant='contained' size="large" onClick={HandleResetCheckIn} color='error'>Retry</Button>
                    </Box>
                </Box>
            )
        }

    }

    const displayCamera = webcamReady ? 'block' : 'none';
    const displayIcon = webcamReady ? 'none' : 'flex';

    return (
        <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
            <Box sx={{width:'100%', height:'100%', position:'relative'}}>
                <Webcam
                    audio={false}
                    height='100%'
                    ref={webcamRef}
                    screenshotFormat="image/png"
                    width='100%'
                    videoConstraints={videoConstraints}
                    onUserMedia={() => setWebcamReady(true)}
                    style={{display: displayCamera}}
                />

                <Box sx={{display:displayIcon,  height:'100%', width:'100%', flexDirection:'column', justifyContent:'center', alignItems:'center', backgroundColor:'#eeeeee'}}>
                    <QrCode2Icon sx={{fontSize:100, display:displayIcon, color:'textSecondary'}} />
                    <Typography variant='h6' display={displayIcon}>
                        Camera is starting up
                    </Typography>
                </Box>

                {checkInState > 1 ? (
                <Box sx={{position:'absolute', width:'100%', height:'100%', top:0, right:0}}>
                    <Box sx={{width:'100%', height:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                        <DisplayResult />
                    </Box>
                </Box> ) : null }
            </Box>
            
            <Box sx={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'center'}} mt={2}>
                <Box sx={{ m: 1, position: 'relative' }}>
                    <RenderActionButtons canScan={webcamReady}/>
                </Box>
            </Box>
        </Box>
      );

}