import React, {useEffect, useState, useContext } from "react";
import { makeStyles } from '@mui/styles';
import ResourcesView from "../components/resources/ResourcesView";
import AuthContext from '../context/AuthContext';
import UserLogin from "../components/user/UserLogin";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';



const useStyles = makeStyles({
        root: {
            flexGrow: 1,
        }
    });


    export default function Resources(props) {
        const { id } = props?.match?.params;

        const [paramsCategory, setParamsCategory] = useState("Module 1");
        const authContext = useContext(AuthContext);
        const [aState, setAState] = useState(authContext.accountState);



        useEffect(() => {
            switch(id) {
                case "module1":
                    setParamsCategory("Module 1");
                    break;
                case "module2":
                    setParamsCategory("Module 2");
                    break;
                case "journey":
                    setParamsCategory("Journey");
                    break;
                case "mobilization":
                    setParamsCategory("Mobilization");
                    break;
            }

        }, [id]);

        React.useEffect(() => {
            console.log("Dashboard: " + JSON.stringify(authContext.accountState));
            setAState({...authContext.accountState});
        }, [authContext.accountState]);
    
        //check for token, block if not logged in
        if(aState.loggedIn === false) {
          return <UserLogin />
        }

        return (
            <Box>
                <Grid container >
                    <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                        <Box>
                            <ResourcesView category={paramsCategory} showHeader={true} />
                        </Box>
                    </Grid>
                    <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                    </Grid>
                </Grid>
            </Box>

      )
  }