import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningIcon from '@mui/icons-material/Warning';
import PendingIcon from '@mui/icons-material/Pending';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CircularProgress from '@mui/material/CircularProgress';

export default function ReportDisplay(props) {
    const {eventData, onAccept, onReject} = props;

    const [reportData, setReportData] = useState(null);
    const [viewParticipants, setViewParticipants] = useState(false);
    const [showSubmittingSF, setShowSubmittingSF] = useState(false);
    const [showSuvmittingIgnore, setShowSubmittingIgnore] = useState(false);

    useEffect(() => {
        setReportData(eventData.report);
    }, [eventData]);

    const PrettyDate = (date) => {
        let d = new Date(date);
        let month = d.toLocaleString('default', { month: 'long' });
        let day = d.getDate();
        let year = d.getFullYear();

        return month + " " + day + ", " + year;
    }

    const GetStatusIcon = () => {
        if(reportData === undefined || reportData === null) {
            let currentDate = new Date();
            if(eventData?.endingDate < currentDate) {
                return <CancelIcon color="error" />;
            } else {
                return <PendingIcon color="warning" />;
            }
        }

        if(reportData.reportStatus === 'Completed' || reportData.reportStatus === 'Finalized') {
            if(reportData.adminAppState === 0) {
                return <CheckCircleOutlineIcon color="warning" />;
            }

            if(reportData.adminAppState === 1) {
                return <CheckCircleIcon color="success" />;
            }

            if(reportData.adminAppState === 2) {
                return <WarningIcon color="error" />;
            }
        } else {
            return <WarningIcon color="error" />;
        }
    }

    const GetStatusText = () => {
        if(reportData === undefined || reportData === null) {
            let currentDate = new Date();
            if(eventData?.endingDate < currentDate) {
                return "Report Not Submitted";
            } else {
                return "Event Pending";
            }
        }

        if(reportData.reportStatus === 'Completed' || reportData.reportStatus === 'Finalized') {
            if(reportData.adminAppState === 0) {
                return "Submitted - Pending Approval";
            }

            if(reportData.adminAppState === 1) {
                return "Approved";
            }

            if(reportData.adminAppState === 2) {
                return "Rejected";
            }
        } else {
            return "Report Not Submitted";
        }
    }
    
    const DisplayParticipants = () => {
        if(viewParticipants) {
            return (
                <Paper style={{maxHeight: 200, overflow: 'auto', padding:5}}>
                    {eventData?.participants?.length === 0 ? <Typography variant="body1" component="p">No Participants Registered</Typography> : 
                    <List>
                        {eventData?.participants?.map((participant, index) => (
                            <Box key={index}>
                                <Typography variant="body1" component="p">
                                    {participant.fName} {participant.lName} - {participant.completed ? "Completed" : "Not Completed"}
                                </Typography>
                            </Box>
                        ))}
                    </List>
                    }
                </Paper>
            );
        } else {
            return null;
        }
    } 

    const SetParticipantsVisibility = (visible) => {
        console.log('SetParticipantsVisibility: ', visible);
        setViewParticipants(visible);
    }

    const VisibilityOffBtn = () => {
        return (
            <IconButton aria-label="delete" size="large" onClick={() =>SetParticipantsVisibility(false)}>
                <VisibilityIcon />
            </IconButton>
        );
    }

    const VisibilityOnBtn = () => {
        return (
            <IconButton aria-label="delete" size="large" onClick={() =>SetParticipantsVisibility(true)}>
                <VisibilityOffIcon />
            </IconButton>
        );
    }
    
    const DisplayRegisteredParticipants = () => {
        return (
            <Box>
                <Box style={{maxHeight: 200, overflow: 'auto', padding:5, border: '1px solid #ccc', borderRadius: 5}}>
                <Typography variant="p" component="p" sx={{fontWeight: 600}}>
                    Participant List {eventData?.participants?.length ? '(' + eventData?.participants?.length + ')' : null}:
                    {viewParticipants ? <VisibilityOffBtn /> : <VisibilityOnBtn />}
                </Typography>   
                {eventData?.participants?.length === 0 ? <Typography variant="body1" component="p">No Participants Registered</Typography> : 
                    <DisplayParticipants />
                }

            </Box>
        </Box>
        )
    }

    const OnAcceptReport = () => {
        setShowSubmittingSF(true);

        console.log('Accept Report');
        if(onAccept)
            onAccept(eventData.id);
    }

    const OnRejectReport = () => {
        setShowSubmittingIgnore(true);

        console.log('Reject Report');
        if(onReject)
            onReject(eventData.id);
    }

    if(reportData === undefined || reportData === null) {
        return (
            <Box>
                <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                    <Typography variant="h6">Report</Typography>
                        <Box><GetStatusIcon /></Box>
                </Stack>
                <Box>
                    <Box sx={{position:'relative', display:'flex', flexDirection:'column'}} >
                        <Box>
                            <Typography variant="body1">
                                No Report Data
                            </Typography>
                            <Typography variant="body1">
                                Due Date: {PrettyDate(eventData.endingDate)}
                            </Typography>
                        </Box>
                        <Box mt={1}>
                            <DisplayRegisteredParticipants />
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }

    const DisplayApproveIgnoreBtns = () => {
        if(reportData.reportStatus === 'Completed' && reportData.adminAppState === 0) {
            if(eventData?.salesforceID === null || eventData?.salesforceID === undefined || eventData?.salesforceID === '') {
                return (
                    <Box>
                        <Typography variant="body1">
                            Please set Salesforce ID before approving.
                        </Typography>
                    </Box>
                );
            }

            return (
                <Box sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
                    <Typography variant="body1">
                        Approve and upload to Salesforce?
                    </Typography>

                <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
                    <Box sx={{ m: 1, position: 'relative' }}>
                        <Button variant="contained" color="primary" size="small" disabled={showSubmittingSF || showSuvmittingIgnore} onClick={() => {OnAcceptReport()}} startIcon={<CloudDoneIcon />}>Approve</Button>
                        {showSubmittingSF && (
                            <CircularProgress
                                size={24}
                                sx={{
                                color: 'green',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                                }}
                            />
                            )}
                    </Box>
                    <Box sx={{ m: 1, position: 'relative' }}>
                        <Button variant="contained" color="secondary" size="small" disabled={showSubmittingSF || showSuvmittingIgnore} onClick={() => {OnRejectReport()}}  startIcon={<CancelIcon />}>Ignore</Button>
                        {showSuvmittingIgnore && (
                            <CircularProgress
                                size={24}
                                sx={{
                                color: 'red',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                                }}
                            />
                            )}
                    </Box>
                </Stack>
                </Box>
            );
        }

        return null;
    }
    

    //display Report status, reported data, completed participant count
    return (
        <Box>
            <Box>
                <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                    <Typography variant="h6">Report</Typography>
                        <Box><GetStatusIcon /></Box>
                </Stack>
            </Box>
            <Box>
            <Box sx={{position:'relative', display:'flex', flexDirection:'column'}}>
                <Box>
                    <Typography variant="body1">
                        Submitted: {PrettyDate(reportData.createdAt)}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="body1">
                        Reported Participants: {reportData.completedParticipants}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="body1">
                        Status: {GetStatusText()}
                    </Typography>
                </Box>
                <Box mt={1}>
                    <DisplayRegisteredParticipants />
                </Box>
                <Box mt={2}>
                    <DisplayApproveIgnoreBtns />
                </Box>
            </Box>
        </Box>
        </Box>
    );



}