import React, { useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import bcrypt from 'bcryptjs';
import SophConstants from '../../constants/SophConstants';
import { AldersgateContext } from '../../context/aldersgate';
import TOSView from '../legal/TOSView';
import PrivacyView from '../legal/PrivacyView';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import CountrySelect from '../CountrySelect';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import CircularProgress from '@mui/material/CircularProgress';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

//functional compoment, user registration form
export default function UserRegistration(props) {

    const { userRegistrationData } = props;

    const [regData, setRegData] = React.useState({
        fName: '',
        lName: '',
        email: '',
        alias: '',
        pword: '',
        password2: '',
        showPassword: false,
        showPassword2: false,
        country: '',
        state: '',
        city: '',
        phoneNumber: '',
        capchavalue: '',
        tosConfirm: false,
        privacyPolicyConfirm: false
      });

    const [formDisabled, setFormDisabled] = React.useState(false);
    const [submitting, setSubmitting] = React.useState({status:0});
    const [alertData, setAlertData] = React.useState({open:false, message:"", severity:"success"});
    const [value, setValue] = React.useState(0);
    const [tosModalOpen, setTosModalOpen] = React.useState(false);
    const [privacyModalOpen, setPrivacyModalOpen] = React.useState(false);
    const [showEmailMessage, setShowEmailMessage] = React.useState(false);
    const [loginBtnDisabled, setLoginBtnDisabled] = React.useState(true);

    const recaptchaRef = React.createRef();

    const aldersgateContext = useContext(AldersgateContext);

    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('md'));
    const xsMatches = useMediaQuery(theme.breakpoints.up('sm'));

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#ffffff',
        border: '2px solid #000',
        maxHeight: '100%',
        overflow:'auto',
        boxShadow: 24,
        p: 4,
        width:(smMatches) ? '40%' : '85%',
    };

    useEffect(() => {
        if(userRegistrationData) {
            setRegData(prevState => {
                let newState = {...prevState};
                newState.fName = userRegistrationData.fName;
                newState.lName = userRegistrationData.lName;
                newState.email = userRegistrationData.email;
                newState.phoneNumber = userRegistrationData.phone;
                newState.country = userRegistrationData.countryID;
                newState.state = userRegistrationData.state;
                newState.city = userRegistrationData.city;
                return {...newState};
            });
        }
    }, [value]);

    
    const history = useHistory();
    const redirect = () => {
        setShowEmailMessage(true);

        setTimeout(()=> {setLoginBtnDisabled(false)}, 5000);
        //history.push("login");
    }

    const handleClick = () => {
        setAlertData(prevState => {
            let newState = {...prevState};
            newState.open = true;
            return {...newState};
        });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlertData(prevState => {
            let newState = {...prevState};
            newState.open = false;
            return {...newState};
        });
    };

    const handleClickShowPassword = () => {
        setRegData({
          ...regData,
          showPassword: !regData.showPassword,
        });
      };

    const handleClickShowPassword2 = () => {
        setRegData({
            ...regData,
            showPassword2: !regData.showPassword2,
        });
    };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    const handleChange = (prop) => (event) => {
        setRegData({ ...regData, [prop]: event.target.value });
    };

    const handleCountryChange = (event, values) => {
        let code = "";
        if(values && values.code)
        code = values.code;

        setRegData(prevState => {
            let newState = {...prevState};
            newState.country = code;
            return {...newState};
        });

    };

    const handleSubmit = (event) => {
        OnSubmit();
    }

    const OnSubmit = async () => {

        if(!VerifyForm())
            return;

        setFormDisabled(true);
        console.log(regData);
        setSubmitting({status:1});

        const token = await recaptchaRef.current.executeAsync();
        regData.capchavalue = token;

        var hash = bcrypt.hashSync(regData.pword, SophConstants.slt);

        regData.pword = hash;
        

        aldersgateContext.PostToAPI('user/addaccount', regData, null).then((success, err) => {
            if(success) {
                setSubmitting({status:2});

                let newState = {open:true, message:"Account Created", severity:"success"}
                setAlertData(newState);

                //redirect to login page
                setTimeout(redirect, 1000);

            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });

        // fetch(SophConstants.API_ADDR + '/api/user/addaccount?api-key=foo', {
        //     method: 'post',
        //     mode: 'cors', // no-cors, *cors, same-origin
        //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //     credentials: 'same-origin', // include, *same-origin, omit
        //     headers: {
        //         'Content-Type': 'application/json'
        //       },
        //     body: JSON.stringify(regData)
        // }).then((res) => {
        //     return res.json();
        // }).then((jsonData, status) => {
        //     setSubmitting({status:2});
        //     //console.log(jsonData);
        //     //setFormDisabled(true);
        //     if(jsonData.status === 201) {
        //         let newState = {open:true, message:jsonData.msg, severity:"success"}
        //         setAlertData(newState);

        //         //redirect to login page
        //         setTimeout(redirect, 1000);

        //     } else {

        //     }
        // }).catch((err) => {
        //       console.log(err);
        // });
    }

    function VerifyForm() {
        if(regData.fName === "") {
            let newState = {open:true, message:"First name required", severity:"error"}
            setAlertData(newState);
            return false;
        }

        if(regData.lName === "") {
            let newState = {open:true, message:"Last name required", severity:"error"}
            setAlertData(newState);
            return false;
        }
        
        if(regData.email === "") {
            let newState = {open:true, message:"Email address required", severity:"error"}
            setAlertData(newState);
            return false;
        }

        if(!ValidatePassword(regData.pword)) {
            return false;
        }
        
        return true;
    }

    function ValidatePassword(p) {
        if(regData.pword === "") {
            let newState = {open:true, message:"Password required", severity:"error"}
            setAlertData(newState);
            return false;
        }

        if(regData.pword !== regData.password2) {
            let newState = {open:true, message:"Password does not match", severity:"error"}
            setAlertData(newState);
            return false;
        }

        if (p.length < 8) {
            let newState = {open:true, message:"Your password must be at least 8 characters", severity:"error"}
            setAlertData(newState);
            return false;
        }

        if (p.search(/[a-z]/i) < 0) {
            let newState = {open:true, message:"Your password must contain at least one letter.", severity:"error"}
            setAlertData(newState);
            return false;
        }

        if (p.search(/[0-9]/) < 0) {
            let newState = {open:true, message:"Your password must contain at least one digit.", severity:"error"}
            setAlertData(newState);
            return false;
        }

        return true;
    }

    function wait(delay){
        return new Promise((resolve) => setTimeout(resolve, delay));
    }

    function SubmitButtonIcon(props) {
        switch (props.submitStatus) {
            case 0:
                return (<SendIcon />); 
            case 1:
                return (<CircularProgress size={20}/>);
            case 2:
                wait(500).then(() => setSubmitting({status:3}));
                return (<CheckIcon />);
            default:
                return (<CheckIcon />);
        }
    }

    const handelTOSCheckChanged = (event) => {
        let isChecked = event.target.checked;

        if(isChecked) {
            setTosModalOpen(true);
        } else {
            setRegData(prevState => {
                let newState = {...prevState};
                newState.tosConfirm = event.target.checked;
                return {...newState};
            });
        }

    }

    const handelPrivacyCheckChanged = (event) => {
        let isChecked = event.target.checked;

        if(isChecked) {
            setPrivacyModalOpen(true);
        } else {
            setRegData(prevState => {
                let newState = {...prevState};
                newState.privacyPolicyConfirm = event.target.checked;
                return {...newState};
            });
        }
    }

    const handelTOSClose = () => {
        setTosModalOpen(false);
    }

    const handelTOSAccept = () => {
        setRegData(prevState => {
            let newState = {...prevState};
            newState.tosConfirm = true;
            return {...newState};
        });
        setTosModalOpen(false);
    }

    const handelPrivacyAccept = () => {
        setRegData(prevState => {
            let newState = {...prevState};
            newState.privacyPolicyConfirm = true;
            return {...newState};
        });
        setPrivacyModalOpen(false);
    }

    const handelPrivacyClose = () => {
        setPrivacyModalOpen(false);
    }

    const PasswordsMatch = () => {
        if(regData.pword === regData.password2) {
            return true
        }

        return false;
    }

    const PasswordsLength = () => {

        if (regData.pword.length < 8) {
            return false;
        } else {
            return true;
        }
    }

    const PasswordsLetter = () => {
        if (regData.pword.search(/[a-z]/i) < 0) {
            return false;
        } else {
            return true;
        }
    }

    const PasswordsDigit = () => {
        if (regData.pword.search(/[0-9]/) < 0) {
            return false;
        } else {
            return true;
        }
    }

    const PasswordsValid = () => {
        if(PasswordsLength() && PasswordsLetter() && PasswordsDigit() && PasswordsMatch()) {
            return true;
        } else {
            return false;
        }
    }

    const PasswordEmpty = () => {
        if(regData.pword === "" && regData.password2 === "") {
            return true;
        } else {
            return false;
        }
    }

    const EmailValid = (email) => {
        let re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const SubmitBtnEnabled = () => {
        if(!PasswordsValid()) {
            return false;
        }

        if(!EmailValid(regData.email)) {
            return false;
        }

        if(regData.fName === "" || regData.lName === "" || regData.email === "" || regData.pword === "" || regData.password2 === "" || regData.country === "" || regData.state === "" || !regData.tosConfirm || !regData.privacyPolicyConfirm) {
            return false;
        } else {
            return true;
        }
    }

    if(showEmailMessage) {
        return (
            <div>
                <Grid container >
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={1} sm={1} md={2} lg={2} xl={3}>
                            </Grid>
                            <Grid item xs={10} sm={10} md={8} lg={8} xl={6} mt={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', gap:'20px'}}>
                                            <Box>
                                                <CheckIcon color="primary" style={{fontSize: 75}}/> 
                                            </Box>
                                            <Box>
                                            <Typography component="h2" variant="h5" color="textPrimary" align="left">
                                                Account Created
                                            </Typography>
                                            <Typography component="p" variant="p" color="textSecondary" align="left">
                                                Please check your email for a verification link
                                            </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            component={Link}
                                            disabled={loginBtnDisabled}
                                            to="/user/login"
                                            endIcon={<SendIcon />}>
                                                Go to Sign In
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1} sm={1} md={2} lg={2} xl={3}>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }

    
    //return form fields: First Name, Last Name, Email, Password, Confirm Password, submit button
    return (
        <div>
            <Grid container >
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={1} sm={1} md={2} lg={2} xl={3}>
                        </Grid>
                        <Grid item xs={10} sm={10} md={8} lg={8} xl={6} mt={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography component="h2" variant="h5" color="textPrimary" align="left">
                                        Create an account
                                    </Typography>
                                    <Typography component="p" variant="p" color="textSecondary" align="left">
                                        Join the ILI team of global leaders
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField id="fName" label="First Name" value={regData.fName} variant="outlined" size="small" onChange={handleChange('fName')} disabled={formDisabled} fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField id="lName" label="Last Name" value={regData.lName} variant="outlined" size="small" onChange={handleChange('lName')} disabled={formDisabled} fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField id="email" label="Email" value={regData.email} variant="outlined" size="small" disabled={formDisabled} onChange={handleChange('email')} fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <Box sx={{mb:0.5}} htmlFor="outlined-adornment-password" >
                                        <InputLabel>Password</InputLabel>
                                    </Box>
                                    <OutlinedInput
                                        fullWidth
                                        size="small"
                                        id="outlined-adornment-password"
                                        type={regData.showPassword ? 'text' : 'password'}
                                        value={regData.pword}
                                        disabled={formDisabled}
                                        onChange={handleChange('pword')}
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            >
                                            {regData.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <Box sx={{mb:0.5}} htmlFor="outlined-adornment-password2" >
                                        <InputLabel>Confirm Password</InputLabel>
                                    </Box>
                                    <OutlinedInput
                                        fullWidth
                                        size="small"
                                        id="outlined-adornment-password2"
                                        type={regData.showPassword2 ? 'text' : 'password'}
                                        value={regData.password2}
                                        disabled={formDisabled}
                                        onChange={handleChange('password2')}
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword2}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            >
                                            {regData.showPassword2 ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                        label="Confirm Password"
                                    /> 
                                </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                {PasswordsLength() || PasswordEmpty() ? null : <Typography component="p" variant="p" color="error" align="left">Password must be 8 or more characters long</Typography>}
                                {PasswordsLetter() || PasswordEmpty() ? null : <Typography component="p" variant="p" color="error" align="left">Password must contain at least one letter</Typography>}
                                {PasswordsDigit() || PasswordEmpty() ? null : <Typography component="p" variant="p" color="error" align="left">Password must contain at least one number</Typography>}
                                {PasswordsMatch() || PasswordEmpty() ? null : <Typography component="p" variant="p" color="error" align="left">Passwords do not match</Typography>}
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <CountrySelect handleCountryChange={handleCountryChange} currentCountry={regData.country} />
                                </Grid>
                                
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField id="state" label="Province/State" value={regData.state} variant="outlined" size="small" disabled={formDisabled} onChange={handleChange('state')} fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField id="city" label="City" value={regData.city} variant="outlined" size="small" fullWidth disabled={formDisabled} onChange={handleChange('city')} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                                <FormControlLabel required control={<Checkbox onChange={handelTOSCheckChanged} checked={regData.tosConfirm} />} label="I have read and agree to ILI's Terms of Service" />
                                <FormControlLabel required control={<Checkbox onChange={handelPrivacyCheckChanged} checked={regData.privacyPolicyConfirm} />} label="I have read and agree to ILI's Privacy Policy" />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={handleSubmit}
                                    
                                    disabled={!SubmitBtnEnabled()}
                                    endIcon={<SubmitButtonIcon submitStatus={submitting.status}/>}>
                                        Submit
                                </Button>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey="6Ld3ExsqAAAAAMmV9WIBJgxFMKGYLYHRrdo7OgBM"
                                />
                                    
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography component="p" variant="p" color="textSecondary" align="left">
                                        By registering, I agree to ILI's terms of service and privacy policy
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={1} sm={1} md={2} lg={2} xl={3}>
                        </Grid>
                    </Grid>
                    <Snackbar open={alertData.open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={alertData.severity} sx={{ width: '100%' }}>
                            {alertData.message}
                        </Alert>
                    </Snackbar>
                </Grid>
            </Grid>

            <Modal
                open={tosModalOpen}
                onClose={handelTOSClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box>
                    <Box sx={style}>
                        <Box mb={10}>
                            <TOSView/>
                        </Box>
                    </Box>
                    <Box sx={{position:'absolute', bottom:10, width:'100%', display:'flex', gap:'20px', justifyContent:'center'}}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handelTOSClose}
                            endIcon={<CancelIcon />}>
                                Disagree
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handelTOSAccept}
                            endIcon={<CheckIcon />}>
                                Accept
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={privacyModalOpen}
                onClose={handelTOSClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box>
                    <Box sx={style}>
                        <Box mb={10}>
                            <PrivacyView/>
                        </Box>
                    </Box>
                    <Box sx={{position:'absolute', bottom:10, width:'100%', display:'flex', gap:'20px', justifyContent:'center'}}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handelPrivacyClose}
                            endIcon={<CancelIcon />}>
                                Disagree
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handelPrivacyAccept}
                            endIcon={<CheckIcon />}>
                                Accept
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}