import React, { useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../../../context/aldersgate';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import BackupIcon from '@mui/icons-material/Backup';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';


export default function SFImportModal(props) {
    const { closeModal } = props;

    const [salesforceID, setSalesforceID] = useState("");
    const [eventData, setEventData] = useState(null);
    const [searchButtonDisabled, setSearchButtonDisabled] = useState(false);
    const [importButtonDisabled, setImportButtonDisabled] = useState(true);

    const aldersgateContext = useContext(AldersgateContext);

    const FetchEventData = () => {
        //get event data
        if(salesforceID == undefined || salesforceID == null || salesforceID == "") {
            console.log("No Event ID");
        } else {
            console.log("eventID: " + JSON.stringify(salesforceID, null, 2));
            aldersgateContext.GetFromAPI('admin/sfconference/' + salesforceID, null, 1).then((response) => {
                console.log(response);
                if(response !== undefined && response !== null) {
                    setEventData(response);
                    setImportButtonDisabled(false);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    const ConfirmImport = () => {
        //import event data
        setImportButtonDisabled(true);

        if(salesforceID == undefined || salesforceID == null || salesforceID == "") {
            console.log("No Event ID");
        } else {
            console.log("eventID: " + JSON.stringify(salesforceID, null, 2));
            aldersgateContext.PostToAPI('admin/sfconference/' + salesforceID, {salesforceID:salesforceID}, null, 1).then((response) => {
                console.log(response);
                if(response !== undefined && response !== null) {
                    if(response?.success) {
                        console.log("Event imported successfully");
                    } else {
                        console.log("Event import failed 1");
                    }
                } else {
                    console.log("Event import failed 2");
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }


    const PrettyDate = (date) => {
        let d = new Date(date);
        let month = d.toLocaleString('default', { month: 'long' });
        let day = d.getDate();
        let year = d.getFullYear();

        return month + " " + day + ", " + year;
    }

    const OnInputChange = (event) => {
        setSalesforceID(event.target.value);
    }

    const OnSearch = () => {
        FetchEventData();
    }

    // simple search input and button
    return (
        <Box>
            <Box sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', gap:'15px'}}>
                <Box>
                    <TextField size="small" id="search" label="Search SFID" variant="outlined" value={salesforceID} onChange={OnInputChange} fullWidth/>
                </Box>
                <Box>
                    <Button variant="contained" color="primary" startIcon={<VisibilityIcon/>} disabled={searchButtonDisabled} onClick={FetchEventData}>Search</Button>
                </Box>
            </Box>
            <Box mt={2}>
                <Paper elevation={1} sx={{padding:'15px'}}>
                    <Box>
                        <Typography variant="h6">Event Information</Typography>
                    </Box>
                    <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start'}}>
                        <Box>
                            <Typography variant="body1">SalesforceID:&nbsp;
                            {eventData?.salesforceID ?
                            <Link href={"https://iliteam.lightning.force.com/lightning/r/Conference__c/" + eventData?.salesforceID + "/view"} target="_blank" rel="noopener noreferrer">
                                {eventData?.salesforceID}
                            </Link>
                            : ''}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body1">Event Name: {eventData ? eventData.name : ''}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="body1">Event Date: {eventData ? PrettyDate(eventData.startingDate) : ''} - {eventData ? PrettyDate(eventData.endingDate) : ''}</Typography>
                        </Box>
                        <Box mt={1}>
                            <Button variant="contained" color="primary" disabled={importButtonDisabled} onClick={ConfirmImport} startIcon={<BackupIcon/>}>Import</Button>
                        </Box>

                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}