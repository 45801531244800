import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AldersgateContext } from '../../../../context/aldersgate';
import EventGroupManagement from './EventGroupManagement';
import ReportDisplay from './ReportDisplay';
import EventSFIDField from './EventSFIDField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import BackupIcon from '@mui/icons-material/Backup';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';


export default function EventViewModal(props) {
    const { eventID, closeModal } = props;

    const [eventData, setEventData] = useState({});
    const [deleteContentConfirm, setDeleteContentConfirm] = useState(false);

    const aldersgateContext = useContext(AldersgateContext);
    const history = useHistory();

    useEffect(() => {

        FetchEventData();
        
    }, [eventID]);

    const FetchEventData = () => {
        //get event data
        if(eventID == undefined || eventID == null) {
            console.log("No Event ID");
        } else {
            let includeModels = "include=participants&include=owner&include=groups";
            console.log("eventID: " + JSON.stringify(eventID, null, 2));
            aldersgateContext.GetFromAPI('event/' + eventID, includeModels, 1).then((response) => {
                console.log(response);
                if(response !== undefined && response !== null) {
                    setEventData(response);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }


    const PrettyDate = (date) => {
        let d = new Date(date);
        let month = d.toLocaleString('default', { month: 'long' });
        let day = d.getDate();
        let year = d.getFullYear();

        return month + " " + day + ", " + year;
    }

    const GetLocationString = ()=> {
        let locationString = [];
        if(eventData.countryID !== undefined) {
            if(eventData.city !== undefined && eventData.city !== null && eventData.city !== "")
                locationString.push(eventData.city);
            if(eventData.state !== undefined && eventData.state !== null && eventData.state !== "")
                locationString.push(eventData.state);
            if(eventData.countryID !== undefined && eventData.countryID !== null && eventData.countryID !== "")
                locationString.push(eventData.countryID);
        }

        let returnString = locationString.join(", ");
        if(returnString === "") {
            return "Unknown Location";
        } else {
            return (
                <Link href={"https://www.google.com/maps/search/" + returnString} target="_blank" rel="noreferrer">
                    {returnString}
                </Link>
            );
        }


    }

    const OnAcceptReport = () => {
        console.log("Accept Report");

        // Call API to accept report
        aldersgateContext.PostToAPI('admin/conference/report', {adID:eventData.report.adID, eventID:eventData.eventID, astatus:1}, null, 1).then((response) => {
            console.log(response);
            FetchEventData();
        }).catch((error) => {
            console.log(error);
            FetchEventData();
        });

    }

    const OnRejectReport = () => {
        console.log("Reject Report");
        // Call API to accept report
        aldersgateContext.PostToAPI('admin/conference/report', {adID:eventData.report.adID, eventID:eventData.eventID, astatus:2}, null, 1).then((response) => {
            console.log(response);
            //refresh the list
            FetchEventData();
        }).catch((error) => {
            console.log(error);
            FetchEventData();
        });
    }

    const UpdateSalesforceID = (sfID) => {
        console.log("Update Salesforce ID: " + sfID);

        // Call API to update Salesforce ID
        aldersgateContext.PostToAPI('admin/event/update', {eventData:{eventID:eventData.eventID, salesforceID:sfID}}, null, 1).then((response) => {
            console.log(response);
            FetchEventData();
        }).catch((error) => {
            console.log(error);
            FetchEventData();
        });
    }

    const OnCreateInSalesforce = () => {
        console.log("Create in Salesforce");

        let eventIDData = {adID:eventData.eventID};

        aldersgateContext.PostToAPI('admin/conference/new', eventIDData, null, 1).then((success, err) => { //conference/new
            if(success) {
                console.log(JSON.stringify(success, null, 4));

                if(success.salesforceID !== undefined && success.salesforceID !== null) {
                    setEventData({...eventData, salesforceID:success.salesforceID});
                }
            }
    
            if(err) {
                console.log("Error: " + err);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
        });
    }

    const setSnackbarMessage = (message) => {
        
    }

    const handleCancel = () => {

    };

    const handelConfirmDelete = () => {
        aldersgateContext.PostToAPI('admin/event/delete/' + eventID, {eDelete:true}, null, 1).then((success, err) => {
            if(success) {
    
                //switch back to view mode
                handleCancel();
    
                setSnackbarMessage("Content Deleted!");
            }
    
            if(err) {
                console.log("Error: " + err);
                //switch back to view mode
                handleCancel();
                setSnackbarMessage("Content delete error!");
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
            //switch back to view mode
            handleCancel();
            setSnackbarMessage("Content delete error!");
        });
    }

    const OnViewEvent = () => {
        history.push("/event/view/" + eventData.eventID);
    }

    if(eventData === undefined) {
        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4" component="h4">
                            No Event Data
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    const GetLeaderName = () => {
        let name = "Unknown";
        if(eventData?.user?.fName) {
            name = eventData?.user?.fName
        }

        if(eventData?.user?.lName) {
            name += " " + eventData?.user?.lName;
        }

        return name;
    }

    return (
        <Box>
            <Box sx={{position: 'absolute', top: 0, right: 0}}>
            {deleteContentConfirm ? 
                    <Box m={1}>
                        Delete Event?
                        <IconButton aria-label="confirm" color="success" onClick={handelConfirmDelete}>
                            <CheckCircleIcon />
                        </IconButton>
                        <IconButton aria-label="cancel" color="error" onClick={()=>{setDeleteContentConfirm(false)}}>
                            <CancelIcon />
                        </IconButton>
                        <IconButton aria-label="delete">
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                : 
                <Box m={1}>
                <IconButton aria-label="delete" color="error" onClick={()=>{setDeleteContentConfirm(true)}}>
                    <DeleteIcon />
                </IconButton>
                </Box>
                }
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" alignContent="center">
                        <Typography variant="h5" component="h5">
                            {eventData?.eventName}
                        </Typography>
                        <Box>
                            <IconButton aria-label="View Event" onClick={() => {OnViewEvent()}}><VisibilityIcon /></IconButton>
                        </Box>
                    </Stack>
                    <hr />
                    <Typography variant="body1" component="p">
                        {PrettyDate(eventData?.startingDate)} - {PrettyDate(eventData?.endingDate)}
                    </Typography>
                    <Typography variant="body1" component="p">
                        {GetLocationString()}
                    </Typography>
                    <Typography variant="body1" component="p">
                        {eventData.eventType} - {eventData.eventMedium}
                    </Typography>

                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                        Leader
                    </Typography>
                    <Typography variant="body1" component="p">
                        {GetLeaderName()}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <EventGroupManagement event={eventData} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                        Salesforce ID
                    </Typography>
                    <Typography variant="body1" component="p">
                        {<EventSFIDField eventData={eventData} OnElementChange={UpdateSalesforceID} onCreateInSalesforce={OnCreateInSalesforce} />}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <ReportDisplay eventData={eventData} onAccept={OnAcceptReport} onReject={OnRejectReport} />
                </Grid>
            </Grid>
        </Box>
    );
}