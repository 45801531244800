import React, { useEffect, useState, useContext } from 'react';
import { AldersgateContext } from '../../../../context/aldersgate';
import Box from '@mui/material/Box';
import { Grid, Paper, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';

export default function EventSFIngest(props) {
    const { event, onCompleteMatchInSalesforce, onCreateInSalesforce } = props;

    const [matchedEvents, setMatchedEvents] = useState([]);
    const [eventData, setEventData] = useState({});
    const [fetchingEvents, setFetchingEvents] = useState(false);
    const [selectedSFEvent, setSelectedSFEvent] = useState(-1);
    const [confirmCreateInSF, setConfirmCreateInSF] = useState(false);
    const [showEventCreateWait, setShowEventCreateWait] = useState(false);

    const aldersgateContext = useContext(AldersgateContext);
    

    useEffect(() => {
        console.log('EventSFIngest: ', JSON.stringify(event, null, 2));
        setEventData(event);
    }, [event]);

    useEffect(() => {
        GetRelatedEvents();
    }, [eventData]);

    const GetRelatedEvents = () => {
        if(eventData.eventID === undefined) {
            return;
        }

        setFetchingEvents(true);

        let eventIDData = {adID:eventData.eventID};

        aldersgateContext.PostToAPI('admin/conference/match', eventIDData, null, 1).then((success, err) => { //conference/new
            if(success) {
                console.log("found events: " + JSON.stringify(success, null, 4));

                if(success.length > 0) {
                    let matchedEventsData = [];
                    let count = 0;
                    for(let i = 0; i < success.length; i++) {
                        matchedEventsData.push({...success[i], id: count++});
                        count++;
                    }

                    setMatchedEvents(matchedEventsData);
                }

                setFetchingEvents(false);
            }
    
            if(err) {
                console.log("Error: " + err);
                setFetchingEvents(false);
            }
    
        }).catch((error) => {
            console.log("Error: " + error);
            setFetchingEvents(false);
        });
    }

    const handleChange = (event) => {
        console.log('handleChange: ', event.target.name, event.target.checked);

        let receivedEvents = [];

        if(event.target.checked) {
            if(confirmCreateInSF)
                setConfirmCreateInSF(false);
        }

        matchedEvents.forEach(row => {
            if(row.checked) {
                row.checked = false;
            }

            if(row.id === parseInt(event.target.name)) {
                row.checked = event.target.checked;
                if(row.checked) {
                    setSelectedSFEvent(row.id);
                } else {
                    setSelectedSFEvent(-1);
                }
            }

            receivedEvents.push(row);
        });

        setMatchedEvents(receivedEvents);
    };

    const PrettyDate = (date) => {
        let d = new Date(date);
        let month = d.toLocaleString('default', { month: 'long' });
        let day = d.getDate();
        let year = d.getFullYear();

        return month + " " + day + ", " + year;
    }

    const SetSalesforceEvent = () => {
        let salesforceID = null;

        matchedEvents.forEach(row => {
            if(row.id === parseInt(selectedSFEvent)) {
                salesforceID = row.SFID;
            }
        });

        if(salesforceID)
            onCompleteMatchInSalesforce(salesforceID);
    }

    const CreateInSalesforce = () => {
        setShowEventCreateWait(true);

        if(onCreateInSalesforce)
            onCreateInSalesforce();
    }

    const DisplayMatchedEvents = () => {
        let matchedEventsDisplay = [];

        if(matchedEvents.length === 0) {
            return (
                <Box mt={1} ml={1} mr={1}>
                    <Stack direction="column" justifyContent="center" alignItems="center">
                    <Typography variant="body1" component="p">No Matching Events Found</Typography>
                    <Box>
                        <IconButton aria-label="Refresh" onClick={() => {GetRelatedEvents()}}><RefreshIcon /></IconButton>
                    </Box>
                    </Stack>
                </Box>
            );
        }

        matchedEvents.forEach(eventRow => {
            matchedEventsDisplay.push(
                <Box key={eventRow.name} sx={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignContent:'center', alignItems:'flex-start'}}>
                    <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', alignContent:'center'}} ml={1}>
                        <FormControlLabel control={<Checkbox checked={eventRow.checked} onChange={handleChange} name={eventRow.id} />} />
                    </Box>
                    <Box sx={{display:'flex', flexDirection:'column', alignContent:'center', alignItems:'center'}}>
                        <Grid container>
                            <Grid item xs={12}>
                            <Link href={"https://iliteam.lightning.force.com/lightning/r/Conference__c/" + eventRow.SFID + "/view"} target="_blank" rel="noopener noreferrer">
                                <Typography variant="body1">
                                    {eventRow.name}
                                </Typography>
                            </Link>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    {PrettyDate(eventRow.startingDate)} - {PrettyDate(eventRow.endingDate)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    Status: {eventRow.eventStatus}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                
            );
        });

        //<GroupDisplay key={group.id} group={group} setGroupData={SetGroupData} />

        return (
            <FormGroup>
                <Box sx={{maxHeight:'250px', display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start', overflow:'scroll'}}>
                    {matchedEventsDisplay}
                </Box>
            </FormGroup>
        );
    }

    const DisplayCreateInSalesforceBtn = () => {
        if(confirmCreateInSF) {
            return (
                <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                <Button variant="contained" color="primary" onClick={() => {CreateInSalesforce()}}>Confirm Create?</Button>
                <Button variant="contained" color="secondary" onClick={() => {setConfirmCreateInSF(false)}}>Cancel</Button>
                </Stack>
            );
        }
        return (
            <Button variant="contained" disabled={selectedSFEvent >= 0} color="primary" onClick={() => {setConfirmCreateInSF(true)}}>Create New in SF</Button>
        );
    }

    if(showEventCreateWait) {
        return (<Box><CircularProgress /></Box>);
    }

    return (
        <Box>
            <Box>
            <Stack direction="column" spacing={1} justifyContent="flex-start" alignItems="flex-start">
                <Typography variant="p">Possible Duplicates in Salesforce:</Typography>
                <Paper>
                    <Box sx={{overflow:'scroll'}}>
                        {fetchingEvents ? <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignContent:'center', alignItems:'center', width:'100%'}}><CircularProgress /></Box> : <DisplayMatchedEvents />}
                    </Box>
                    <Box p={2}>
                        <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                        {selectedSFEvent >= 0 ? 
                            <Button variant="contained" disabled={selectedSFEvent < 0} color="primary" onClick={() => {SetSalesforceEvent()}}>Connect Event</Button>
                            : <DisplayCreateInSalesforceBtn />
                        }
                        </Stack>
                    </Box>
                </Paper>
            </Stack>
            </Box>
        </Box>
    );
}