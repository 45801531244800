import React, {useState, useEffect, useContext } from 'react';
import { AldersgateContext } from '../../../context/aldersgate';
import MessageDetails from './MessageDetails';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

const style = {
    bgcolor: 'background.paper',
    border: '2px solid #444',
    p: 4,
    m:2,
  };

function wait(delay){
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default function EditEventMSGs(props) {
    const { eventData, updateInformation } = props;
    const {updateData, setSnackbarMessage} = updateInformation;
    const {eventID } = eventData;
    const [isLoading, setIsLoading] = useState(true);
    const [msgs, setMsgs] = useState({onSignup:{adID:null, commType:null, templateID:null, commSubject:null, commBody:null, commDate:null, personalize:false, commActive:false}});
    const [value, setValue] = useState(0);
    const aldersgateContext = useContext(AldersgateContext);

    useEffect(() => {
        if(eventID != null) {

            aldersgateContext.GetFromAPI('event/' + eventID + '/msgs', null, 1).then((success, err) => {
                if(success) {
                    parseMessageData(success);
                    setIsLoading(false);
                }
        
                if(err) {
                    console.log("Error: " + err);
                    generateStandardMessage('onSignup');
                }
        
            }).catch((error) => {
                console.log("Error: " + error);
                generateStandardMessage('onSignup');
            });

            //fetch(SophConstants.API_ADDR + '/api/event/' + eventID + '/msgs' + "?api-key=foo", {
        }

    }, [value]);

    const parseMessageData = (jsonData) => {
        console.log("msgs: " + JSON.stringify(jsonData, null, 2));
        const {externalmsgs} = jsonData;

        if(externalmsgs != null && externalmsgs.length > 0) {
            setMsgs(prevState => {
                let newState = {...prevState, onSignup:{...prevState.onSignup, ...jsonData.externalmsgs[0]}};
                return {...newState};
            });
        } else {
            generateStandardMessage('onSignup');
        }
    }

    const generateStandardMessage = (msgType) => {
        setMsgs(prevState => {
            let newState = {...prevState, onSignup:{...prevState.onSignup, commType:msgType, commSubject:"Your Leadership Event Registration", 
            commBody:"{FNAME},<br/>Thank you for registering for the <b>{EVENTNAME}</b> event scheduled {STARTING_DATE}-{ENDING_DATE} We look forward to seeing you there.<br/><br/>Blessings,<br/>International Leadership Institute", 
            commDate:null, personalize:false, commActive:true}};
            return {...newState};
        });   
    }

    return (
        <Paper elevation={1}>
            <Grid container spacing={2} sx={{ px: 2, mt:0 }}>
            <Grid item xs={12}>

            </Grid>
            <Grid item xs={12}  sx={{mt:2}}>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={{mr:1}}>
                        <ScheduleSendIcon color="action"/>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" align="left" color="textSecondary">Sign Up Confirmation</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <MessageDetails msgData={{messageData:msgs.onSignup, setMessageData:setMsgs}} updateData={updateData} isActive={eventData?.msgRegisterConfirmationEnabled} msgType={'onSignup'} setSnackbarMessage={setSnackbarMessage} eventID={eventID} />
            </Grid>
            <Grid item xs={12}  sx={{mt:2}}>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={{mr:1}}>
                        <ScheduleSendIcon color="action"/>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" align="left" color="textSecondary">Event Reminder</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}  sx={{mt:2}}>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={{mr:1}}>
                        <ScheduleSendIcon color="action"/>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" align="left" color="textSecondary">After Event Followup</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={0} sm={12} >

            </Grid>
        </Grid>
        </Paper>
    );

}