import React from "react";
import { DashboardEvents, GlobalTrainedCount, GlobalStatsGraph, NavBreadcrumbs } from "./../components";
import AuthContext from '../context/AuthContext';
import UserLogin from "../components/user/UserLogin";
import ADashboardHomeView from "../components/admin/dashboard/Home/ADashboardHomeView";
import ADashboardPostsView from "../components/admin/dashboard/Posts/ADashboardPostsView";
import AEventsView from "../components/admin/dashboard/Events/AEventsView";
import GroupsView from "../components/dashboard/GroupsView";
import APeopleView from "../components/admin/dashboard/People/APeopleView";
import ReportsView from "../components/dashboard/Reports/ReportsView";
import GroupWorkIcon from '@mui/icons-material/GroupWork';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import HomeIcon from '@mui/icons-material/Home';
import ListAltIcon from '@mui/icons-material/ListAlt';
import GroupsIcon from '@mui/icons-material/Groups';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ArticleIcon from '@mui/icons-material/Article';

function DisplayPage(pageNumber) {
    switch(pageNumber) {
        case 0:
            return <ADashboardHomeView />;
        case 1:
            return <AEventsView />;
        case 2:
            return <APeopleView />;
        case 3:
            return <ADashboardPostsView />;
        case 4:
            return <ReportsView />;

        default:
            return <ADashboardHomeView />;
    }
}

export default function AdminDashboard (props) {
    const [navPage, setNavPage] = React.useState(0);

    const authContext = React.useContext(AuthContext);
    const [aState, setAState] = React.useState({loggedIn:false, adID:null, level:0, token:null, expiry:null});
    
    React.useEffect(() => {
        setAState(prev => {
            return({...authContext.accountState});
        });
    }, [authContext]);

    //check for token, block if not logged in
    if(aState.loggedIn === false) {
        return <UserLogin />
    }

    return (
        <Box m={1.5} bgcolor="background.paper" >
            <Grid container >
                <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                </Grid>
                <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>

                    <NavBreadcrumbs crumbs={[]} currentPage="Admin" />

                    {DisplayPage(navPage)}

                </Grid>
                <Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
                </Grid>
            </Grid>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                showLabels
                value={navPage}
                onChange={(event, newValue) => {
                    setNavPage(newValue);
                }}
                >
                <BottomNavigationAction label="Home" icon={<HomeIcon />} />
                <BottomNavigationAction label="Events" icon={<ListAltIcon />} />
                <BottomNavigationAction label="People" icon={<GroupsIcon />} />
                <BottomNavigationAction label="Posts" icon={<ArticleIcon />} />
                <BottomNavigationAction label="Reports" icon={<AssessmentIcon />} />
                </BottomNavigation>
            </Paper>
        </Box>
      )
}
