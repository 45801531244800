import React, { useState, useEffect } from 'react';
import AContentListRow from './AContentListRow';
// import AContentListCard from './AContentListCard';
import Box from '@mui/material/Box';
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';

export default function AContentListItem(props) {
    const { rowData } = props;

    // const theme = useTheme();
    // const smMatches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Box>
            <AContentListRow key={rowData.adID} rowItemData={rowData} />
        </Box>
    );
}